#zonePioneeringAsOurEntire{
    margin-top:550px;
    text-align: center;
    font-size:22px;
    margin-bottom:1cm;
    background:white;
}

#zonePioneeringAsOurEntire>div{
    width:600px;
    display:inline-block;
}

#zoneEveryTimeYouSeeAsolution{
    background:#856379;
    padding-top:1cm;
    padding-bottom:1cm;
    font-size:18px;
}

#zoneImageApolloAndVortex{
    text-align:center;
    position:relative;
    margin-bottom: -5.2cm;
    z-index: 120;
}

#zoneImageApolloAndVortex #ImageVortex{
    position:absolute;
    top:0px;
    width:100%;
    top:2cm;
}

#zoneImageApolloAndVortex #ImageVortex img{
    width:400px;
    z-index:5200;
}



#zoneImageApolloAndVortex #ImageApolloOnlyBlackIcon{
    height:700px; 
    z-index:5000;
}

#zoneImageGreeLeaves{
    position:relative;
    text-align: right;
}

#zoneImageGreeLeaves img{
    position:absolute;
    height:250px;
    right:20px;
    top:-1.5cm;
    
}

#zoneBackgroundMountain{
    height: 600px;
    z-index:100;
    background:url('../res/img/vortex/bg.mountain.png') no-repeat fixed top;
    background-size: 'initial';
}

#zoneBackgroundMountain #zoneGrayInZoneBackgroundMountain{
    background:#d6d6d6;
    font-size:25px;
    text-align:center;
    position:relative;
    top:2cm;
    z-index:10;
    text-transform: capitalize;
    padding-top:1cm;
    padding-bottom:1cm;
}

#BecauseReliabilityIsTruly{
    padding-top:1.5cm;
    padding-bottom:1.5cm;
    color:white;
    background:#856379;
    text-align: center;
    font-size:20px;
}

#zoneVision{
    text-align:center;
    height:1200px;
    background:url('../res/img/vortex/baby.with.mother.png') no-repeat bottom;
    font-size:20px;
}

#zoneVision>div{
    width:600px;
    display: inline-block;
    padding-top:1.5cm;

}

#zoneVision strong{
    text-transform: uppercase;
    font-size:23px;
}

@media only screen and (max-width: 799px) {
    #premierDivVortex{
        background-position-x: -3cm!important;
    }

    #pageVortexReact_ {

    }

    #pageVortexReact_ .divAfterBigDivStarting{
        margin-top:350px!important;
    }

    #zonePioneeringAsOurEntire>div{
        width:300px;
        padding:20px;
    }

    #zoneImageGreeLeaves{
        display:block!important;
        height:250px;
        max-width:100%;
        overflow: hidden;
        text-align: center;
        padding-bottom: 10px;
    }

    #zoneImageGreeLeaves img{
        /*position:absolute;
        height:220px;
        right:27px;
        top:-25px;
        left:13px;
        */
        max-width:100%;
        position: unset;
    }

    #zoneImageGreeLeavesText{
        text-align:center;
    }

    #ImageApolloOnlyBlackIcon{
        display:none;
    }

    #zoneImageApolloAndVortex{
        height:650px;
    }

    #zoneImageApolloAndVortex #ImageVortex img{
        width:250px; 
    }

    #zoneVision>div{
        width:300px;
        padding:20px;

    }

    #zoneEveryTimeYouSeeAsolution{
        max-width: 100%;
        margin:0px;
    }
}

@media only screen and (max-width: 1028px) and (min-width:800px) {

    #zoneEveryTimeYouSeeAsolution{
        background: #856379;
        padding-top: 1cm;
        padding-bottom: 1cm;
        font-size: 18px;
        height: 12cm;
    }


    #zoneImageGreeLeaves img{
        position: absolute;
        height: 250px!important;
        right: 5px!important;
        top:0px!important;
        height:5cm!important;
    }

    #zoneImageApolloAndVortex #ImageApolloOnlyBlackIcon{
        height: 700px;
        z-index: 5000;
    }

    #zoneImageApolloAndVortex{
        margin-bottom: 0px;
    }

    #zoneImageApolloAndVortex{
        height:20cm;
    }

    #zoneImageApolloAndVortex #ImageApolloOnlyBlackIcon{
        display:none;
    }
}

@media only screen and (max-width: 400px) {
    #premierDivVortex{
        background-position-x: -4.6cm!important;
    }
}

@media only screen and (max-width: 799px) and (min-width: 401px) {
    #premierDivVortex{
        background-position-x: 0cm!important;
    }

    #zoneBackgroundMountain{
        height: 600px;
        z-index:100;
        background:url('../res/img/vortex/bg.mountain.png') no-repeat top;
        background-size:cover!important;
    }
}