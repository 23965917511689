@font-face{
    src:url("../style/lib/Trasandina-t.ttf");
    font-family:"Trasandina";
}

@font-face{
    src:url("../style/lib/TrasandinaBold.ttf");
    font-family: "Trasandina-Bold";
}

b{
    font-family: "Trasandina-Bold";
}

body{
    font-family: "Trasandina"!important;
    font-weight:600;
}

#zoneMissionVortextOfReliability{
    text-align: center;
    color:white;
    margin-top:10cm;
}

#zoneMissionVortextOfReliability>div{
    width:520px;
    font-size:20px;
    display: inline-block;
}

#zoneMissionVortextOfReliability>div strong{
    text-transform: uppercase;
    font-weight:800;
    font-family:"Trasandina-Bold";
}

#zoneMissionVortextOfReliability>div p{
    font-style:italic;
}

#zonePrincipalSpeaking{
    margin-top:650px;
    text-align: center;
    position: relative;
    z-index:600;
}

#zonePrincipalSpeaking #ImageApolloOnlyBlackIconSpeaking{
    position: absolute;
    width:1200px;
    top:-600px;
    right:-380px;
    z-index:10;
}

#zonePrincipalSpeaking .principalSpeakingSection{
    width:250px;
    padding:20px;
    padding-top:50px;
    margin-right:2cm;
    display:inline-block;
    background:#f1d8cb;
    color:#4d4d4d;
    text-align: left;
    z-index:1300;
}

#zonePrincipalSpeaking .principalSpeakingSection .personSpeaking{
    width:70%;
    float:right;
    margin-top:20px;
    
}

#zonePrincipalSpeaking .principalSpeakingSection .personSpeaking .personSpeakingTitle{
    font-size:12px;
}

#zonePrincipalSpeaking .principalSpeakingSection .personSpeaking .personSpeakingName{
    font-weight:700;
}

#zoneThreeGenerationWomen{
    z-index:100;
    margin-top:-40px;
}

.zonePersonSpeaking{
    text-align: center;
}

.zonePersonSpeaking>div{
    width:700px;
    display:inline-block;
}

.zonePersonSpeaking>div .zoneBiography{
    text-align: left;
    display: inline-block;
    width:80%;
    float:left;
}

.zonePersonSpeaking>div .profilePicture{
    display: inline-block;
    width:20%;
    float:right;
}

.zonePersonSpeaking>div .profilePictureLeft{
    float:left;
}

.zonePersonSpeaking>div .zoneBiographyLeft{
    float:left;
}

.zonePersonSpeaking>div .zoneBiographyRight{
    float:right;
}

.zonePersonSpeaking>div .profilePicture img{
    width:150px;
    margin-top:2cm;
    margin-left:1cm;
}



.zonePersonSpeaking>div .zoneBiography .bioPersonName{
    text-transform: uppercase;
    font-size:25px;
    font-weight: 800;
    margin-bottom: 0.5cm;
    margin-top: 0.5cm;
}

.zonePersonSpeaking>div .profilePictureLeft img{
    width:150px;
    margin-top:2cm;
    margin-right:1cm;
    position:relative;
    left:-3cm;
}

#zoneBryan{
    background:#d6d6d6;
    position:relative;
}

#zoneCharlesJordan{
    padding-bottom: 2cm;
    position:relative;
    width:100%;
    overflow: hidden;
}

#zoneCharlesJordan ul{
    list-style-type: square;
    list-style:none;
}

#zoneCharlesJordan ul li::before {
    content: "■"; 
    color: #d2c2ba; 
    font-weight: bold; 
    display: inline-block; 
    width: 2em; 
    margin-left: -1em; 
    font-size:30px;
    position:relative;
    top:5px;
    left:20px;
}

#zoneKenDunWody{
    position: relative;
    overflow: hidden;
}

#iconBlackKenDunwody{
    position:absolute;
    bottom:-60px;
    left:-250px;
    width:500px;
}

#iconWhiteBryan{
    position:absolute;
    bottom:60px;
    right:-50px;
    width:400px;
}

#iconDottedCharlesJorda{
    position:absolute;
    bottom:40px;
    right:-200px;
    width:500px;
}

@media only screen and (max-width: 650px) {
    #premierDivUs{
        height:500px!important;
    }

    #pageReactUs_ .bigBackgroundsInApp{
        background-size: 280%!important;
        background-position-x: 30%!important;
        
    }

    #zoneMissionVortextOfReliability{
        margin-top:6cm;
    }
    #zoneMissionVortextOfReliability>div{
        width:300px;
    }

    #zonePrincipalSpeaking #ImageApolloOnlyBlackIconSpeaking{
        width:100px;
        display: none;
    }

    #zonePrincipalSpeaking{
        margin-top:450px;
    }

    #zonePrincipalSpeaking .principalSpeakingSection{
        width:250px;
        padding:20px;
        padding-top:50px;
        margin-left:1cm;
        margin-bottom:20px;
        display:inline-block;
        background:#f1d8cb;
        color:#4d4d4d;
        text-align: left;
        z-index:1300;
    }

    #zoneThreeGenerationWomen{
        background-position-x: -5cm!important;
        background-size: 20cm !important;
        height:390px!important;
    }

    .zonePersonSpeaking #iconBlackKenDunwody{
        display:none!important;
    }

    .zonePersonSpeaking>div{
        width:300px;
        display:inline-block;
    }

    .zonePersonSpeaking .profilePicture{
        display:block;
        margin-top:20px;
        text-align: center;
        width:100%;
    }

    .zonePersonSpeaking .profilePicture img{
        position:relative;
        left:0px!important;
    }

    .zonePersonSpeaking>div .profilePictureLeft{
        width:100%;
        float:none;
    }

    .zonePersonSpeaking>div .profilePictureRight{
        width:100%;
        float:none;
    }

    .zonePersonSpeaking>div .profilePicture{
        float:none;
        width:100%;
    }

    #iconWhiteBryan{
        display:none;
    }

    .zonePersonSpeaking .zoneBiography{
        text-align:center;
        width:100%;
        display: block;
    }

    .zonePersonSpeaking>div .zoneBiography{
        width:100%;
    }

    .zonePersonSpeaking .zoneBiography .bioPersonName{
        text-align:center;
        width:100%;
        
    }

    .zonePersonSpeaking .zoneBiography .bioContent{
        text-align:left;
        width:100%;
    }

    @keyframes animationOnKids{
        0%{
            background-position-x: 0cm;
        }25%{
            background-position-x: -9cm;
        }50%{
            background-position-x: -9cm;
        }75%{
            background-position-x: 0cm;
        }
    }

    #zoneKids{
        background-position-y: 1cm!important;
        /*background-position-x: -5cm!important;*/
        animation-name: animationOnKids;
        animation-duration: 10s;
        animation-iteration-count: infinite;
    }

    #zoneCharlesJordan ul li::before {
        content: "■"; 
        color: #d2c2ba; 
        font-weight: bold; 
        display: inline-block; 
        width: 2em; 
        margin-left: -1em; 
        font-size:22px;
        position:relative;
        top:0px;
        left:20px;
    }
}

@media only screen and (max-width: 1028px) and (min-width:801px){
    #zonePrincipalSpeaking #ImageApolloOnlyBlackIconSpeaking{
        position: absolute;
        width: 618px;
        top: -239px;
        right: -202px;
        z-index: 10;
        display:none;
    }

    #iconBlackKenDunwody{
        display:none;
    }

    #iconWhiteBryan{
        display: none;
    }

    #zonePrincipalSpeaking .principalSpeakingSection{
        margin-right:0px;
        border-right:1px solid #f7f7f7;
    }
}



@media only screen and (max-width: 800px) and (min-width:651px){
    #ImageApolloOnlyBlackIconSpeaking{
        display:none;
    }

    #zonePrincipalSpeaking .principalSpeakingSection{
        margin-right:0px;
        border-right:1px solid #f7f7ff;
    }

    .zonePersonSpeaking #iconBlackKenDunwody{
        display:none!important;
    }

    .zonePersonSpeaking>div{
        width:100%;
        padding:20px;
        display:inline-block;
        text-align:center;
    }

    .zonePersonSpeaking .profilePicture{
        display:block;
        margin-top:20px;
        text-align: center;
        width:100%;
        float:none;
    }

    .zonePersonSpeaking>div .zoneBiography{
        width:100%;
        text-align: center;
    }

    .zonePersonSpeaking .profilePicture img{
        position:relative;
        left:0px!important;
    }

    .zonePersonSpeaking>div .profilePictureLeft{
        width:100%;
        float:none;
    }

    .zonePersonSpeaking>div .profilePictureRight{
        width:100%;
        float:none;
    }

    .zonePersonSpeaking>div .profilePicture{
        float:none;
        width:100%;
    }

    .zonePersonSpeaking #iconWhiteBryan{
        display:none;
    }
}

@media only screen and (max-width: 650px) {
    #zoneKids{
        height: 13cm!important;
        background-position-x:-1cm!important;
        background-position-y:0cm!important;
    }
}