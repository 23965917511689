@font-face {
    font-family: "AntitledBold Regular";
    src: url("AntitledBold Regular.ttf");
}

.advantageUsing3{
    height:300px;
    position:relative;
}

.advantageUsing3 .borderDiv{
    background:transparent;
    position: absolute;
    top:0%;
    left:0%;
    margin:2%;
    border:1px solid #d2d1d1;
    width:96%;
    height:96%;
}

.advantageUsing3 .borderDiv1{
    position: absolute;
    top:0%;
    left:65%;
    border-left:1px solid #d2d1d1;
    width:50%;
    height:100%;
    z-index: 700;

}

.advantageUsing3 .zone1SingleMinute{
    width:30%;
    float:left;
    height:100%;
    display: table;
    text-align:center;
    text-transform: uppercase;
    font-size:13px;
}

.advantageUsing3 .zone1SingleMinute>div{
    display:table-cell;
    vertical-align: middle;
    font-family: "AntitledBold Regular";
    color:#856379;
}

.advantageUsing3 .zoneBig2{
    width:70%;
    float:right;
    height:100%;
    display:table;
    border-left:1px solid #d7d7d7;
    position: relative;
}

.advantageUsing3 .zoneBig2>div{
    vertical-align: middle;
    width:100%;
    display: table-cell;
}

.advantageUsing3 .zoneBig2>div .zoneImgBigTestAnyBody{
    width:100%;
}

.advantageUsing3 .zoneBig2>div .zoneImgBigTestAnyBody img{
    width:100%;
    position: relative;
    z-index: 1300;
}

.advantageUsing3 .zoneBig2>div .zoneTestAnyBodyBigText{
    font-size:10px;
    padding-bottom: 7px;
}

.advantageUsing3 .zoneBig2>div .zoneTestAnyBodySmallText{
    font-size:6px;
    display:inline-block;
    width:60%;
    margin-top:10px;
}

.advantageUsing3 .zoneBig2>div .zoneTestImgSmallText {
    display:inline-block;
    width:40%;
}

.advantageUsing3 .zoneBig2>div .zoneTestImgSmallText .imgMachineTestAnyBody{
    width:50px;
    position:relative;
    top:-20px;
    z-index: 1400;
}

.advantageUsing3 .zoneBig2>div .zoneTestImgSmallText .imgMachineLogoAnyBody{
    width:30px;
    position:relative;
    top:-10px;
}