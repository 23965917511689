@font-face {
    font-family: "AntitledBold Regular";
    src: url("AntitledBold Regular.ttf");
}

.advantageUsing2{
    overflow:hidden;
    height:250px;
    margin-bottom:20px;
}

.zoneLeft{
    background:#856379;
    color:white;
    height:100%;
    width:35%;
    float:left;
    text-transform: uppercase;
    font-size:12px;
    text-align:center;
    padding:10px;
    display:table;
    font-family: "AntitledBold Regular";
    vertical-align: middle;

    z-index:10;
    border:1px solid white;
}

.zoneLeft>div{
    display:table-cell;
    vertical-align: middle;
}

.zoneRight{
    background:#856379;
    color:white;
    height:100%;
    width:35%;
    float:right;
    font-size:9px;
    text-align:center;
    padding-top:auto;
    padding-bottom:auto;
    
    border:1px solid white;
    display: table;
    position:relative;
    z-index:15;
}

.zoneRight>div{
    display: table-cell;
    vertical-align: middle;
}

.imgBigAdvtg2{
    width:60px;
}

.imgSmallAdvtg2{
    width:40px;
}

.rightText1{
    font-size:6px;
    padding-bottom:12px;
    font-weight: 600;
    width:100%;
    padding:10px;
}

.rightText2{
    font-size:4px;
    padding:10px;
}

.biggestImageZone{
    float:left;
    max-height: 100%;
    box-shadow: 0px 0px 3px black;
    z-index: 2000;
}

.biggestImageZone img{
    position:absolute;
    top:5px;
    z-index:10;
    left: 36%;
    width:30%;
}

@media only screen and (min-width: 900px) {
    .biggestImageZone img{
        position:absolute;
        height:calc(100% - 10px);
        top:5px;
        z-index:10;
        left: 36%;
        width:auto;
    }
}

