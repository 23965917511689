#zoneTheCovidCleanPass{
    text-align:center;
}

#zoneTheCovidCleanPass>div{
    width:600px;
    display:inline-block;
    padding-top:50px;
    padding-bottom:50px;
    font-size:20px;
}

#zoneWhileHassleFree{
    padding:20px;
    background:rgba(214,214,214,1);
    
}


#zoneImageCleanPass{
    text-align:right;
}

#labelImageCleanPass{
    padding-top:30px;
    font-size:17px;
}

#zoneLogoCovidCleanPass{
    position:relative;
    z-index:6;
}

#labelImageCleanPass{
    z-index:10;
}

#zoneLogoCovidCleanPass img{
    position:absolute;
    left:-3cm;
    height:130px;
    top:1cm;
}

@media only screen and (max-width: 650px) {
    #zonePageProductReact_ .bigBackgroundsInApp{
        background-position-x: -3cm!important;
    }

    #zonePageProductReact_ #imageCleanPassJumbotron{
        top:5cm;
    }

    #fluidJumbutronZone{
        height:0px!important;
    }

    #zoneTheCovidCleanPass{
        margin-top:230px!important;
        
    }

    #topBigBackgroundsInAppProduct{
        height:400px!important;
    }

    #zoneTheCovidCleanPass>div{
        width:300px;
    }

    #zoneImageCleanPass img{
        width:220px!important;
        position:relative;
        left:-30px;
    }

    #zoneWhileHassleFree{
        height:700px;
    }

    #zoneLogoCovidCleanPass img{
        height:80px;
        position:absolute;
        top:-1cm;
        left:20px;
    }

    #zoneSimplyClickOnAnyImage{
        width:300px!important;
    }

    #Andhowdoyoubegintoe{
        width:300px!important;
    }

    #Loremipsumdolor-sit-amet{
        width:300px!important;
    }

    #imageSmilingOldMan{
        left:-18cm;
    }

    #RowAndhowdoyoubegintoe{
        width:100%;
    }

    #zoneWhileHassleFree{
        text-align:center;
        
    }
}

@media only screen and (min-width: 651px) and (max-width: 1000px){
    #zoneWhileHassleFree{
        text-align: center;
        display: block;
        height:15cm;
    }

    #zoneWhileHassleFree #zoneImageCleanPass{
        display:block!important;
        max-width: 100%;
        width:100%;
        text-align: center;
    }

    #zoneWhileHassleFree #labelImageCleanPass{
        display:block!important;
        max-width: 100%;
        width:100%;
        text-align: center;
    }

    #zoneWhileHassleFree #zoneLogoCovidCleanPass{
        display:block!important;
        max-width: 100%;
        width:100%;
        text-align: center;
    }

    #zoneWhileHassleFree #zoneLogoCovidCleanPass img{
        position:unset;
        margin-top:40px;
        height:2.4cm;
    }

}