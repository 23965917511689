.advantageUsing{
    position:relative;
    height:300px;
    background:url("../res/img/bg.linez.png") no-repeat;
    background-size: contain;
    overflow: hidden;
}


.advtgDesign1 .advantageUsingTitle{
    text-transform: uppercase;
    color:#856379;
    font-weight:1200;
    font-size:15px;
    position:absolute;
    left:150px;
    top:70px;
    font-family: "AntitledBold Regular";
}

.advtgDesign1 .advantageUsingImgBig{
    width:130px;
    position:absolute;
    top:80px;
    left:30px;
}

.advtgDesign1 .advantageUsingText1{
    font-size:10px;
    position:absolute;
    top:100px;
    left:180px;
    width:150px;
}

.advtgDesign1 .advantageUsingText2{
    font-size:10px;
    position:absolute;
    bottom:70px;
    right:120px;
    width:150px;
}

.advtgDesign1 .advantageUsingImgSmall{
    width:80px;
    position:absolute;
    bottom:50px;
    right:50px;
}


/* Advtg 2 */

.advtgDesign2 .advantageUsingTitle{
    text-transform: uppercase;
    color:#856379;
    font-weight:1200;
    font-size:15px;
    position:absolute;
    left:80px;
    top:55px;
    width:120px;
    font-family: "AntitledBold Regular";
}

.advtgDesign2 .advantageUsingImgBig{
    width:140px;
    position:absolute;
    top:60px;
    right:20px;
}

.advtgDesign2 .advantageUsingText1{
    font-size:10px;
    position:absolute;
    top:100px;
    left:80px;
    width:120px;
}

.advtgDesign2 .advantageUsingText2{
    font-size:10px;
    position:absolute;
    bottom:90px;
    left:190px;
    width:150px;
}

.advtgDesign2 .advantageUsingImgSmall{
    width:80px;
    position:absolute;
    bottom:60px;
    left:110px;
}

/* Advtg 3 */

.advtgDesign3 .advantageUsingTitle{
    text-transform: uppercase;
    color:#856379;
    font-weight:1200;
    font-size:15px;
    position:absolute;
    left:120px;
    top:50px;
    font-family: "AntitledBold Regular";
}

.advtgDesign3 .advantageUsingImgBig{
    width:170px;
    position:absolute;
    top:60px;
    left:-30px;
}

.advtgDesign3 .advantageUsingText1{
    font-size:10px;
    position:absolute;
    top:80px;
    left:143px;
    width:150px;
}

.advtgDesign3 .advantageUsingText2{
    font-size:10px;
    position:absolute;
    bottom:100px;
    right:90px;
    width:120px;
}

.advtgDesign3 .advantageUsingImgSmall{
    width:90px;
    position:absolute;
    bottom:90px;
    right:10px;
}


/* Advtg 4 */

.advtgDesign4 .advantageUsingTitle{
    text-transform: uppercase;
    color:#856379;
    font-weight:1200;
    font-size:15px;
    position:absolute;
    left:40px;
    top:70px;
    font-family: "AntitledBold Regular";
}

.advtgDesign4 .advantageUsingImgBig{
    width:130px;
    position:absolute;
    bottom:90px;
    right:10px;
}

.advtgDesign4 .advantageUsingText1{
    font-size:10px;
    position:absolute;
    top:100px;
    left:40px;
    width:150px;
}

.advtgDesign4 .advantageUsingText2{
    font-size:10px;
    position:absolute;
    bottom:90px;
    right:150px;
    width:110px;
}

.advtgDesign4 .advantageUsingImgSmall{
    width:60px;
    position:absolute;
    bottom:100px;
    left:30px;
}