#zoneDiscoverHowUniqueHome{
    margin-top:500px;
    background:white;
    text-align: center;
    background-image:url("../res/img/a.icon.big.png");
    background-repeat: no-repeat;
    background-size: 9cm;
    background-position-x: 32cm;
    background-position-y: 1.5cm;

}

#zoneDiscoverHowUniqueHome>div{
    width:500px;
    display: inline-block;
    padding-top:2cm;
    padding-bottom: 1.5cm;;
    font-size:20px;
}

#zoneDiscoverHowUniqueHome>div b{
    font-weight: 900;
    font-family:  "Trasandina-Bold";
}

#row-text-single-characteristic{
    background:#856379;
    color:white;
    height: 6cm;
    overflow: hidden;
}

#row-text-single-characteristic #text-single-characteristic{
    width:70%;
    float:right;
    height:100%;
}

#row-text-single-characteristic #text-single-characteristic>div{
    display: table;
    height:6cm;
}

#row-text-single-characteristic #text-single-characteristic>div>div{
    display: table-cell;
    vertical-align: middle;
    height:100%;
    font-size:18px;
}

#row-text-single-characteristic #img-single-characteristic{
    
}

#row-text-single-characteristic img{
    position:relative;
    top:-3cm;
    width:16cm;
}

#zoneApolloRedIcon{
    padding-top:1.3cm;
    background:#d6d6d6;
    text-align: center;
}

#imgApolloRedIcon{
    width:150px;
}

#Because-Reliabilit-is-Truly{
    padding-top:0.5cm;
    padding-bottom:1cm;
    position: relative;
    z-index: 1500;
    font-size:17px;
}

#ChooseBusinessOrClinicHome{
    position:relative;
    padding-top:1.5cm;
    padding-bottom:1cm;
    z-index: 10;
    margin-top:200px;
    text-align: center;
}

#ChooseBusinessOrClinicHome #IconApolloBlack{
    width:10cm;
    position: absolute;
    top:-2cm;
    left:-2cm;
    z-index:10;
}

#ChooseBusinessOrClinicHome .btn{
    text-transform: capitalize;
}

.buttonApollo{
    padding:10px;
    background:#ed813a!important;
    border:none!important;
    color:white;
    text-transform: uppercase!important;
    font-weight: 700;
    border:none;
    margin:10px;
    width:180px;
}

#txt-our-latest-solution-home{
    width:500px;
    display:inline-block;
    padding-top:2cm;
    padding-bottom: 1cm;
    font-size:20px;
}



#Uncover-today-the-unprecedented{
    background: #7d93db;
    color:white;
    padding-top:1cm;
}

#Uncover-today-the-unprecedented>p{
    width:500px;
    font-size:24px;
    display:inline-block;
}

#zoneSmilingGuy{
    position:relative;
   
}


#zoneSmilingGuy img{
    height:2.5cm; 
    position: absolute;
    top:350px;
    left:40%;
}

#our-latest-solution-home{
    background:white;
    padding-top:1cm;
    padding-bottom: 1cm;
}

#our-latest-solution-home strong{
    font-size:24px;
    margin-top:10px;
    margin-bottom:10px;
    text-transform: uppercase;
    color:gray;
}

#home-display-apollo-machine{
}
#home-display-apollo-machine img{
    
}

#Uncover-today-the-unprecedented{
}

#Uncover-today-the-unprecedented img{
    
}

#home-display-apollo-case{
    background: white;
}

#Uncover-today-the-unprecedented{
    position:relative;
}

#home-apollo-case{
    display:inline-block;
    width:400px;
    position:absolute;
    top:-110px;
    left:50px;
}

#home-apollo-machine1{
    display:inline-block;
    width:400px;
    position:absolute;
    top:-110px;
    right:50px;
}

@media only screen and (max-width: 650px) {
    .bigBackgroundsInApp{
        background-size: 150%!important;
        background-position-x: 0cm!important;
        
    }

    #ChooseBusinessOrClinicHome{
        position: relative;
        padding-top: 0.1cm;
        padding-bottom: 1cm;
        z-index: 5000;
        margin-top: 2.5cm!important;
        text-align: center;
    }

    #zonePageHomeReact_ .divAfterBigDivStarting{
        margin-top:300px!important;
    }

    #zoneDiscoverHowUniqueHome{
        margin-top:450px;
    }
    
    #zoneDiscoverHowUniqueHome>div{
        width:300px;
        display: inline-block;
        padding-top:0;
        padding-bottom: 0.5cm;;
        font-size:18px;
        padding-left:20px;
        padding-right:20px;
    }
    
    #zoneDiscoverHowUniqueHome>div b{
        font-weight: 900;
        font-family:  "Trasandina-Bold";
    }
    
    #row-text-single-characteristic{
        background:#856379;
        color:white;
        height: 15cm;
        overflow: hidden;
    }
    
    #row-text-single-characteristic #text-single-characteristic{
        width:100%;
    }
    
    #row-text-single-characteristic #text-single-characteristic>div{
        display: table;
        height:6cm;
    }
    
    #row-text-single-characteristic #text-single-characteristic>div>div{
        display: table-cell;
        vertical-align: middle;
        height:100%;
        text-align: center;
    }
    
    #row-text-single-characteristic #img-single-characteristic{
        position: relative;
        top: 0.5cm;
        margin-bottom: 220px;
        
    }
    
    #row-text-single-characteristic img{
        position:relative;
        top:0cm;
        width:10cm;
    }
    
    #zoneApolloRedIcon{
        background:#d6d6d6;
        text-align: center;
    }
    
    #Because-Reliabilit-is-Truly{
        padding-top:0.5cm;
        padding-bottom:1cm;
        position: relative;
        z-index: 1500;
    }
    
    #ChooseBusinessOrClinicHome{
        position:relative;
        padding-top:1.5cm;
        padding-bottom:1cm;
        z-index: 10;
        margin-top:200px;
        text-align: center;
    }
    
    #ChooseBusinessOrClinicHome #IconApolloBlack{
        width:10cm;
        position: absolute;
        top:-2cm;
        left:-2cm;
        z-index:10;
    }
    
    #ChooseBusinessOrClinicHome .btn{
        text-transform: capitalize;
    }
    
    .buttonApollo{
        padding:10px;
        background:orange!important;
        border:none!important;
        color:white;
        text-transform: uppercase!important;
        font-weight: 700;
        border:none;
        margin:10px;
        width:150px;
    }
    
    #txt-our-latest-solution-home{
        width:300px;
        display:inline-block;
        padding-top:2cm;
        padding-bottom: 1cm;
        padding-left:20px;
        padding-right:20px;
        
    }
    
    
    
    #Uncover-today-the-unprecedented{
        background: #7d93db;
        color:white;
        padding-top:1cm;
    }
    
    #Uncover-today-the-unprecedented>p{
        width:300px;
        font-size:22px;
        display:inline-block;
    }
    
    #zoneSmilingGuy{
        position:relative;
        background-position-x: -5cm!important;
    }
    
    
    #zoneSmilingGuy img{
        height:1cm; 
        position: absolute;
        top:350px;
        left:30%;
    }
    
    #our-latest-solution-home{
        background:white;
        padding-top:1cm;
        padding-bottom: 1cm;
    }
    
    #our-latest-solution-home strong{
        font-size:20px;
        margin-top:10px;
        margin-bottom:10px;
        text-transform: uppercase;
    }

    #our-latest-solution-home img{
        height:1.5cm!important;
    }
    
    #home-display-apollo-machine{
        display:inline-block;
    }
    
    #Uncover-today-the-unprecedented{
    }
    
    #Uncover-today-the-unprecedented img{
        
    }
    
    #home-display-apollo-case{
        background: white;
    }

    #home-display-apollo-case img{
        width:220px;
    }

    #Uncover-today-the-unprecedented{
        height:20cm;
    }

    #Uncover-today-the-unprecedented>p{
        margin-top:6.2cm;
    }

    #home-apollo-case{
        display:block;
        width:250px;
        position: absolute;
        top:50px;
        left:40px;
        
    }
    
    #home-apollo-machine1{
        display:block;
        width:250px;
        position:absolute;
        top:14cm;
        left:40px;
    }

    #zoneSmilingGuy{
        background:url('../res/img/home/guy.apollo.png') no-repeat!important;
        background-size:30cm!important;
        background-position-x: -4cm!important;
    }
}

@media only screen and (max-width: 1028px) and (min-width:651px) {
    #home-apollo-case{
        width:220px;
        
    }
    
    #home-apollo-machine1{
        width:220px;
    }

    #row-text-single-characteristic img{
        width:12cm;
        left:0cm;
        top:0px;
    }

    #our-latest-solution-home img{
        height:1.8cm!important;
    }
}