#Two-of-the-most-valued-elements{
    margin-top:550px;
    text-align: center;
    padding-bottom:1cm;
    background:white;
    padding-top:20px;
}

#Two-of-the-most-valued-elements>div{
    display:inline-block;
    width:600px;
    font-size:18px;

}

#zoneDisplayCleanPassBlue{
    background:rgba(214,214,214,1);
    padding-top:1cm;
    padding-bottom:1cm;
    text-align:center;
}

#zoneDisplayCleanPassBlue #cleanPassBlue{
    width:250px;
}

#zoneDisplayCleanPassBlue #machineCleanPass{
    width:220px;
}

#More-coming-soon{
    padding-top:1.5cm;
    padding-bottom:1.5cm;
    background:#856379;
    color:white;
    text-align:center;
    font-size:19px;
}

#More-coming-soon>div{
    width:600px;
    display:inline-block;
}

#More-coming-soon>div strong{
    text-transform: uppercase;
    margin-bottom: 20px;
    display: block;
}

#ChooseBusinessOrClinic{
    height:500px;
    position:relative;
    background-position-x: 3.2cm!important;
    background-position-y: -2.3cm!important;
}

#ChooseBusinessOrClinic>div{
    width:60%;
    height:600px;
    background:#d6d6d6;
    display: table;
}

#ChooseBusinessOrClinic>div>p{
    display: table-cell;
    vertical-align: middle;
    text-align: right;
}

#ChooseBusinessOrClinic>div>p .buttonApollo{
    padding:10px;
    background:orange;
    color:white;
    text-transform: uppercase;
    font-weight: 700;
    border:none;
    margin:10px;
    margin-right:25%;
    width:150px;
}

#ChooseBusinessOrClinic>div #IconApolloBlack{
    width:300px;
    position:absolute;
    top:150px;
    left:2cm;
}

#zoneDisplayVortexImage{
    padding-top:1cm;
    padding-bottom:1cm;
    background:white;
    text-align:center;
    
    background-position-x: 8.5cm!important;
    background-position-y: -15cm!important;
}

#zoneDisplayVortexImage img{
    width:250px;
}

#zonePeopleMeetingTraining{
    width:100%;
    background:url('../res/img/training/bg.people.meeting.png') no-repeat fixed top;
    background-size:cover;
    color: #ccc;
    height: 600px;
    z-index:-2;
    color:black;
}

@media only screen and (max-width: 650px) {
    #reactPageTrainingSupport_{

    }

    #reactPageTrainingSupport_ .bigBackgroundsInApp{
        background-size: 150%!important;
        background-position-x: -3.4cm!important;
        background-position-y: 0cm!important;
    }

    #Two-of-the-most-valued-elements{
        margin-top:230px;
    }

    #zonePeopleMeetingTraining{
        width:100%;
        background:url('../res/img/training/bg.people.meeting.png') no-repeat ;
        background-size:20cm;
        
        color: #ccc;
        height: 13cm;
        z-index:-2;
        color:black;

        animation-name: animationOnPeopleInMeeting;
        animation-duration: 10s;
        animation-iteration-count: infinite;
    }

    @keyframes animationOnPeopleInMeeting{
        0%{
            background-position-x: 0cm;
        }25%{
            background-position-x: -9cm;
        }50%{
            background-position-x: -9cm;
        }75%{
            background-position-x: 0cm;
        }
    }

    #topDivTrainingSupport{
        background-position-x: -600px!important;
    }

    #Two-of-the-most-valued-elements>div{
        display:inline-block;
        width:300px;
        font-size:18px;
    }

    #More-coming-soon>div{
        width:300px;
        display:inline-block;
    }

    #IconApolloBlack{
        display:none;
    }

    #ChooseBusinessOrClinic>div{
        width:50%;
        height:100%;
        background:#d6d6d6;
        display: table;
    }

    #ChooseBusinessOrClinic{
        background-position-x: -13.5cm!important;
        position:relative;
    }

    #ChooseBusinessOrClinic>div{
        height:30%;
        position:absolute;
        bottom:3cm;
        padding:10px;
        left:-10px;
        border-radius:10px;
    }
}

@media only screen and (max-width: 650px) and (min-width: 500px){
    #ChooseBusinessOrClinic{
        background-position-x: -10.5cm!important;
        position:relative;
    }

}

@media only screen and (max-width: 1025px) and (min-width: 650px){
    #ChooseBusinessOrClinic>div #IconApolloBlack{
        width:200px;
        position:absolute;
        top:50%!important;
        left:10px;
    }
}

@media only screen and (max-width: 900px) and (min-width: 650px){
    #ChooseBusinessOrClinic{
        background-position-x: 33%!important;
        position:relative;
    }
}
