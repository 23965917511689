#zoneFooter{
    background:rgba(214,214,214,1);
    height:200px;
}

#zoneFooter .menuFooter{
    display:inline-block;
    padding:1cm;
    float:left;
}

#zoneFooter .menuFooter ul{
    padding-left:0px;
}

#zoneFooter .menuFooter ul>li{
    list-style: none;
    font-size:20px;
    text-transform: uppercase;
    color:rgba(96,96,96,1);
}

#zoneFooter .menuFooter ul>li a{
    color:rgba(96,96,96,1);
}

.menuSocialMedia{
    float:right;
    margin-right:2cm;
    margin-top:1cm;
}

.menuSocialMedia ul{
    list-style:none;
}

.menuSocialMedia ul li{
    display:inline-block;
}

#menuFooterHomeUs{
    border-left:3px solid white;
    margin-top:20px;
}

@media only screen and (max-width: 650px) {
    #zoneFooter{
        height:600px;
    }

    #zoneFooter .menuFooter{
        display:block;
        padding:1cm;
        padding-top:20px;
        padding-bottom:20px;
        float:left;
    }

    .menuSocialMedia{
        float:left;
    }

    #menuFooterHomeUs{
        border-left:none;
        margin-top:0px;
    }
}

@media only screen and (min-width: 650px)  and (max-width: 900px){
    .menuSocialMedia{
        width:100%;
        display:block;
        text-align: center;
    }

    #zoneFooter{
        height:300px;
    }

    #menuFooterHomeUs{
        border-left:none;
        margin-top:0px;
    }
}