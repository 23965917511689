@font-face {
    font-family: "AntitledBold Regular";
    src: url("AntitledBold Regular.ttf");
}

.advantageUsing4{
    height:230px;
    padding:0px!important;
    margin:0px!important;
    position: relative;
    margin-bottom:30px!important;
}



.advantageUsing4 .imageBackground{
    position: absolute;
    top:0;
    left:0;
    width:100%;
    z-index: 0;
    height: 100%;
}

.advantageUsing4 .divBorderAvtg4{
    position: absolute;
    left:18px;
    border-right:1px solid #d7d7d7;
    z-index: 1500;
    height:105%;
    top:-2.5%;
}

.advantageUsing4 .divBorder2Avtg4{
    position: absolute;
    left:-2%;
    border-bottom:1px solid #d7d7d7;
    z-index: 1500;
    width:98%;
    top:60px;
}

.advantageUsing4 .imageBackground img{
    position: absolute;
    top:1%;
    right:25px;
    z-index: 700;
    height:98%;
}

.imageMainBackground{
    position:absolute;
    right:20px;
    height:100%;
    width:115px;
    top:0%;
    z-index:7500;
    
}

.divBorderMainBckgAvtg4{
    position:absolute;
    height:105%;
    top:-2.5%;
    right:20px;
    width:115px;
    background:transparent;
    z-index:2700;
    border-right:1px solid #d7d7d7;
    border-left:1px solid #d7d7d7;
}

.advantageUsing4 .zoneForeGround{
    position: relative;
    width:65%;
    background:#f7f5f3;
    height:100%;
    display: table;
    z-index: 1450;

}

.zoneForeGround>div{
    display:table-cell;
    vertical-align: middle;
    padding-left:20px;
}

.smallImage{
    position:absolute;
    top:60px;
    left:0px;
    width:18px;
    z-index: 7000;
}

.advantageUsing4 .zoneSmallerText{
    margin-top:15px;

}

.advantageUsing4 .zoneForeGround .zoneNoOtherWayToSeeIt{
    font-family: "AntitledBold Regular";
    text-transform: uppercase;
    color:#86637a;
    position:relative;
    top:-10px;
}

.advantageUsing4 .zoneForeGround .todayApolloMedco{
    font-size:8px;
    width:150px;
    margin-left:20px;
}

.advantageUsing4 .zoneForeGround .imageUsing4Machine{
    display:inline-block;
    width:80px;
}

.advantageUsing4 .zoneForeGround .imageUsing4Machine img{
    width:50px;
    position:relative;
    top:-30px;
    left:20px;
}

.advantageUsing4 .zoneForeGround .textUsing4SmallerText{
    font-size:6px;
    display:inline-block;
    width:60%;
}

.advantageUsing4 .zoneForeGround .imgClearPassBlueUsing4{

}